.thead {
    font-size: .8em;
}
.table-row {
    font-size: .8em;
    height: 4rem;
    border: 1px dashed transparent;
}

.project-row:nth-child(odd) .inner-project-row {
    background-color: #f8f8f8;
}

.project-row:nth-child(even) .inner-project-row {
    background-color: #ede9e8;
}

.table-row > div {
    display: flex;
    align-items: center;
}

.table-row > div > span {
    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: break-word;
    max-height: 9rem;
}

.project-row {
    border: 1px dashed transparent;
}

.project-row.expanded {
    border: 2px dotted #8a8a8a;
}

.project-row.expanded .inner-project-row {
    border-bottom: 1px dashed #a5a5a5;
}

.project-row.selected {
    background-color: #7ed956;
    border: 1px dashed #575757;
}

.project-row.selected > .inner-project-row {
    background-color: #7ed956;
}

.project-row.expanded.selected {
    background-color: #b8d5ac;
}

.subproject-row {
    border: 1px dashed transparent;
}

.subproject-row:not(:last-child) {
    border-bottom: 1px dotted #cdcdcd;
}

.subproject-row.selected {
    background-color: #7ed956 !important;
    border: 1px dashed #575757 !important;
}

.time-cell {
    font-size: .8em;
    font-weight: 600;
    text-align: right;
}

.id {
    font-size: 1.1em;
}

.customer {
    font-size: .9em;
}

.old-projects-split {
    border-bottom: 6px dotted #7f7f7f !important;
    height: 4em;
}

.recent-projects {
    border-bottom: 6px dotted #7f7f7f !important;
}

@media (min-width: 992px) {
    .thead {
        font-size: 1em;
    }
    .table-row {
        font-size: 1.2em;
        height: 3rem;
        border: 5px dashed transparent;
    }

    .table-row > div {
        display: flex;
        align-items: center;
    }

    .table-row > div > span {
        overflow: hidden;
        text-overflow: ellipsis;
        word-wrap: break-word;
        max-height: 9rem;
    }

    .time-cell {
        font-size: .9em;
        font-weight: 600;
        text-align: right;
    }

    .projectName {
        font-size: .9em;
    }

    .id {
        font-size: 1.1em;
        vertical-align: center;
    }

    .customer {
        font-size: 1em;
    }

    .search-input {
        min-height: calc(1.5em + 1.1rem + calc(var(--bs-border-width)* 2));
    }

    .loadMore {
        font-size: 1.3em;
    }
}