.button {
    cursor: pointer;
    border: none;
    height: auto;
    max-width: 100%;
    transition: filter 0.3s;
    background-color: transparent;
}
.button > img {
    padding: .3em;
    width: 3rem;
    height: 3rem;
}

.button[disabled] {
    filter: grayscale(80%) blur(7px);
    cursor: unset;
}

.button:active:not([disabled]) {
    filter: blur(5px);
}

@media (min-width: 576px) {
    .button > img {
        width: 4rem;
        height: 4rem;
    }
}

/*loader*/
.loader {
    width: 25px;
    height: 25px;
    border: 3px solid #FFF;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@media (min-width: 992px) {
    .loader {
        width: 48px;
        height: 48px;
        border: 5px solid #FFF;
    }
}