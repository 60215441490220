.pin-container {
    max-width: 17rem;
}
.pin-digit {
    width: 3rem;
    height: 3rem;
    border-bottom: 3px solid black;
    margin-bottom: 3rem;
    font-size: 2em;
}

.keypad {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
}

.keypad .key {
    width: 3rem;
    height: 3rem;
    border: 2px solid black;
    border-radius: 50%;
    font-size: 2em;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    outline: none;
    background-color: white;
    -webkit-user-select: none;
    user-select: none;
    transition: background-color 0.3s;
}

.keypad .key[disabled] {
    color: #3B3B3B;
    border-color: #3B3B3B;
}

.key.zero {
    grid-column-start: 2;
}

.keypad .key:active {
    background-color: lightgray;
}

@media (min-width: 992px) {
    .pin-container {
        max-width: unset;
    }
    .pin-digit {
        width: 5rem;
        height: 5rem;
        border-bottom: 4px solid black;
        margin-bottom: 2rem;
        font-size: 3em;
    }

    .keypad {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 10px;
    }

    .keypad .key {
        width: 6rem;
        height: 6rem;
        border: 2px solid black;
        border-radius: 50%;
        font-size: 3em;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        outline: none;
        background-color: white;
        -webkit-user-select: none;
        user-select: none;
        transition: background-color 0.3s;
    }

    .key.zero {
        grid-column-start: 2;
    }

    .keypad .key:active {
        background-color: lightgray;
    }
}
