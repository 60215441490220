body {
    overflow-y: scroll;
}
.message {
    position: absolute;
    top: 50px;
    left: 0;
    right: 0;
    font-size: 1em;
    padding-right: 30px;
    padding-left: 30px;
}

.message .alert {
    padding-right: 2.5em;
}

@media (min-width: 992px) {
    .message {
        position: absolute;
        top: 100px;
        left: 0;
        right: 0;
        font-size: 1.5em;
        padding-right: 30px;
        padding-left: 30px;
    }

    .message .alert {
        padding-right: 2em;
    }
}