.header {
    background-color: #d9d9d9;
}
.a {
    width: 14% !important;
}
.b {
    width: 44% !important;
}

.time {
    color: #1bc45f;
    font-size: 1.5em;
    margin-bottom: 0;
}

/*switch*/
.big-switch.form-switch .form-check-input {
    width: 2em;
    height: 1em;
}

.big-switch.form-switch .form-check-input:checked {
    background-color: #00d54f;
    border-color: #001d00;
}

.big-switch.form-switch .form-check-input:focus {
    --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27%2376ffa9%27/%3e%3c/svg%3e");
}
.big-switch.form-switch .form-check-input:focus {
    border-color: #76ffa9;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgb(210 255 230);
}

.dropdown-menu {
    --bs-dropdown-font-size: 1em;
    --bs-dropdown-padding-y: 0rem;
}
.dropdown-item {
    background-color: #d9d9d9;
}
.checkbox-label {
    font-size: .8em;
}

.projectInfo {
    font-size: .6em;
}

.list-group-item img {
    width: 3rem;
}

.list-group-item button {
    cursor: pointer;
}

@media (min-width: 992px) {
    .checkbox-label {
        font-size: calc(.7rem + 0.6vw);
    }
    .header {
        background-color: #d9d9d9;
    }
    .a {
        width: 14% !important;
    }
    .b {
        width: 44% !important;
    }

    .time {
        color: #1bc45f;
        font-size: 2.5em;
    }

    /*switch*/
    .big-switch.form-switch .form-check-input {
        width: 3rem;
        height: 2rem;
    }

    .big-switch.form-switch .form-check-input:checked {
        background-color: #00d54f;
        border-color: #001d00;
    }

    .big-switch.form-switch .form-check-input:focus {
        --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27%2376ffa9%27/%3e%3c/svg%3e");
    }
    .big-switch.form-switch .form-check-input:focus {
        border-color: #76ffa9;
        outline: 0;
        box-shadow: 0 0 0 0.25rem rgb(210 255 230);
    }

    .dropdown-menu {
        --bs-dropdown-font-size: 1.5em;
        --bs-dropdown-padding-y: 0rem;
    }
    .dropdown-item {
        background-color: #d9d9d9;
    }

    .projectInfo {
        font-size: 1.3em;
        max-height: 7.5rem;
    }
}